/* index.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

.content {
  flex: 1;
  padding-top: 110px; /* Hauteur de la barre de navigation */
  padding-bottom: 40px; /* Hauteur du pied de page */
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar {
    padding: 15px 0;
  }

  .content {
    padding-top: 130px; /* Ajustez cette valeur en fonction de la hauteur de la barre de navigation sur mobile */
    padding-bottom: 60px; /* Ajustez cette valeur en fonction de la hauteur du pied de page sur mobile */
  }

  .footer {
    padding: 15px 0;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 20px 0;
  }

  .content {
    padding-top: 150px; /* Ajustez cette valeur en fonction de la hauteur de la barre de navigation sur mobile */
    padding-bottom: 80px; /* Ajustez cette valeur en fonction de la hauteur du pied de page sur mobile */
  }

  .footer {
    padding: 20px 0;
  }
}