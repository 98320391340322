/* Header.css */
.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
}

.navbar h1 {
  margin: 0;
  margin-bottom: 10px;
}

.hamburger {
  display: none;
  font-size: 2em;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-links a:hover,
.nav-links a:focus {
  background-color: #555;
  color: #fff;
  border-radius: 4px;
}

.nav-links a:active {
  background-color: #777;
  color: #fff;
  border-radius: 4px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background-color: #333;
    position: absolute;
    top: 60px;
    left: 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }
}
